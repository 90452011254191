import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import * as styles from './marken.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import DefaultLayout from '../layout/DefaultLayout';

const MarkenPage = ({ data, location }) => {
  const { pageData, siteData, brands } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>Marken</PageTitle>
      <div className="defaultWidth">
        {brands.edges.map(({ node }) => (
          <div className={styles.brand}>
            <div
              style={{
                minWidth: 300,
                maxWidth: 300,
                padding: 16,
              }}
            >
              <a href={node.homepage} target="__blank" rel="noopener noreferrer">
                <GatsbyImage image={getImage(node.logo)} alt={''} />
              </a>
            </div>
            <div
              style={{
                minWidth: 300,
                textAlign: 'left',
                flexGrow: 1,
                padding: 16,
              }}
            >
              <h3>{node.name}</h3>
              <div dangerouslySetInnerHTML={{ __html: node.description }} />
              {node.homepage && (
                <a href={node.homepage} target="__blank" rel="noopener noreferrer">
                  zur {node.name} Seite
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </DefaultLayout>
  );
};

export default MarkenPage;

export const MarkenPageQuery = graphql`
  query MarkenPageQuery {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageMarken {
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
    brands: allCockpitMarken {
      edges {
        node {
          name
          description
          homepage
          logo {
            id
            childImageSharp {
              gatsbyImageData(
                width: 320
                transformOptions: { fit: CONTAIN }
                backgroundColor: "#fff"
                placeholder: NONE
              )
            }
          }
        }
      }
    }
  }
`;
